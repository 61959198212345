
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "css-vars-ponyfill";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueSlideoutPanel from "vue2-slideout-panel";
import Vuelidate from "vuelidate";
import App from "./App";
import router from "./router/index";
import store from "./store/store";
import DefaultContainerLayout from "@/layouts/DefaultContainer.vue";
import NoMenuLayout from "@/layouts/NoMenu.vue";
import OrderTemplatesSlideOut from "@/components/orders/OrderTemplatesSlideOut.vue";
import Snotify from "vue-snotify";
import axiosSetup from '@/shared/axios';
import loggingRepository from '@/services/loggingRepository';
import VueLogger from 'vuejs-logger';
import { addToLogStorage, getLogStorage, clearLogs } from '@/logs/logStorage'
import { amountOfErrorsToSend, timeToWaitInSeconds, environment } from '@/shared/configurations'


//#region Logger
// Configure vuejs-logger

const options = {
  isEnabled: true, // Enable logging
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug', // Set log level
  stringifyArguments: false, // Optionally stringify arguments
  showLogLevel: true, // Show log level in output
  showMethodName: true, // Show method name in output
  separator: '|', // Separator between log messages
};

Vue.use(VueLogger, options)

// Intercept log messages and store them in the logStorage array
Vue.config.errorHandler = function (err, vm, info) {
  addToLogStorage({
    level: 'error',
    message: err.message,
    component: vm.$options.name,
    info,
  })
}

function sendLogsToApi(logs) {
	if(process.env.NODE_ENV === 'development' || environment === 'Development' ){
		console.log("🚀 Errors thrown:", logs);
	}else{
		loggingRepository.postErrors(logs);
	}
	clearLogs();
}

// Interval for checking log count and sending logs
setInterval(() => {
  const logs = getLogStorage();
  if (logs.length >= amountOfErrorsToSend) {
    sendLogsToApi(logs);
  }
}, timeToWaitInSeconds); // Check every 5 seconds (adjust as needed)

// Send logs before page unload
window.addEventListener('beforeunload', () => {
  const logs = getLogStorage();
  if (logs.length > 0) {
    sendLogsToApi(logs);
  }
});

// Send logs when the page is completely unloaded
window.addEventListener('unload', () => {
  const logs = getLogStorage();
  if (logs.length > 0) {
    sendLogsToApi(logs);
  }
});

// Send logs when the user switches tabs or exits full-screen mode
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    const logs = getLogStorage();
    if (logs.length > 0) {
      sendLogsToApi(logs);
    }
  }
});

//#endregion

Vue.component("default-container-layout", DefaultContainerLayout);
Vue.component("no-menu-layout", NoMenuLayout);
Vue.component("order-template-slide-out", OrderTemplatesSlideOut);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueSlideoutPanel);
Vue.use(Snotify, {
  toast: {
    timeout: 15000
  }
});

axiosSetup();

new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: {
    App
  }
});
